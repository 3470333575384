
import {defineComponent, ref} from 'vue'
import CustomerInboxService from "@/core/services/CustomerInboxService";

export default defineComponent({
  name: "CusSendMessageBox",
  props: {
    customerId: {}
  },
  emits: ['send'],
  setup() {
    const model = ref<any>({message: '', cusId: ''});
    return {
      model,
    }
  },
  methods: {
    onRead() {
      CustomerInboxService.read(this.customerId);
    },
    onSend() {
      this.model.cusId = this.customerId;
      CustomerInboxService.create(this.model).then(res => {
        this.$emit('send', res)
      }).finally(() => {
        this.model = {message: '', cusId: ''}
      })
    }
  }
})
