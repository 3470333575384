
import {defineComponent, ref, watch} from 'vue'
import CusMessageIn from "@/views/customer/inbox/CusMessageOut.vue";
import CusMessageOut from "@/views/customer/inbox/CusMessageIn.vue";
import CusSendMessageBox from "@/views/customer/inbox/CusSendMessageBox.vue";
import CustomerInboxService from "@/core/services/CustomerInboxService";

export default defineComponent({
  name: "CustomerChatBox",
  components: {CusSendMessageBox, CusMessageOut, CusMessageIn},
  props: {
    customerId: {}
  },
  setup(props) {
    const messagePage = ref<any>({
      total: 0,
      messages: [],
      pages: 0,
    });
    const filter = ref({page: 0, max: 20, cusId: props.customerId});
    const messagesRef = ref<null | HTMLElement>(null);
    const load = (filter: any) => {
      CustomerInboxService.load(filter.cusId, filter.page, filter.max).then(res => {
        const first = messagePage.value.messages;
        messagePage.value.messages = res.data.concat(first);
        messagePage.value.pages = res.pages
        messagePage.value.total = res.total
      })
    }
    watch(() => props.customerId, cb => {
      filter.value.page = 0;
      filter.value.max = 20;
      filter.value.cusId = cb
      messagePage.value.messages = []
      load(filter.value);
    })
    load(filter.value);
    return {
      messagesRef,
      filter,
      messagePage,
      load,
    }
  },
  methods: {
    onScroll() {
      if (this.messagesRef) {
        if (this.messagesRef.scrollTop == 0) {
          const page = this.filter.page + 1;
          if (page >= this.messagePage.pages) return
          this.filter.page = page;
          this.load(this.filter);
        }
      }
    },
    onSend(message) {
      this.messagePage.messages.push(message);
    },
    loadToBottom() {
      if (this.messagesRef != null) {
        if (this.filter.page == 0) {
          this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
        }
      }
    },
  },
  updated() {
    this.loadToBottom();
  },

  mounted() {
    if (this.messagesRef != null) {
      this.messagesRef.addEventListener('scroll', this.onScroll)
    }
  },

  unmounted() {
    if (this.messagesRef != null) {
      this.messagesRef.removeEventListener('scroll', this.onScroll)
    }
  }
})
