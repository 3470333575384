
import {computed, defineComponent} from 'vue'
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import store from "@/store";

export default defineComponent({
  name: "CusMessageIn",
  components: {DateTimeFormat},
  props: {
    message: {type: Object}
  },
  setup() {
    const entity = computed(() => store.state.CustomerModule.entity);
    return {
      entity
    }
  }
})
