
import {defineComponent} from 'vue'
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "CusMessageOut",
  components: {DateTimeFormat},
  props: {
    message: {type:Object}
  }
})
